import React from "react";
import { getVendeur } from "../HomePage/HomePage";
import naImage from "../../assets/images/appImages/naPageImage.png";
import { TfiWorld } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

const PageNotAvailable = () => {
  const Vendeur = getVendeur();

  return (
    <div className="p-2 container-body-page-not-available">
      <div className="py-3 custom-container">
        <div className="row page-row">
          <div className="col-lg-6 mb-3 d-flex align-items-center">
            <div className="">
              <h1 className="title-home-page pb-3">
                Le site est en <br />
                <span>
                  Maintenance
                </span>
              </h1>
              <p className="m-0 pt-4 social-links-container d-flex align-items-center gap-3">
                <Link
                  className="social__wrapper"
                  to="https://www.facebook.com/AwABioFoods/"
                  target="_blank"
                >
                  <FaFacebookF />
                </Link>
                <Link
                  className="social__wrapper"
                  to="https://www.linkedin.com/company/awabiofoods/"
                  target="_blank"
                >
                  <FaLinkedinIn />
                </Link>
                <Link
                  className="social__wrapper"
                  to="https://www.instagram.com/awa_bio_foods"
                  target="_blank"
                >
                  <FaInstagram />
                </Link>
                <Link
                  className="social__wrapper"
                  to="https://twitter.com/AwaBio"
                  target="_blank"
                >
                  <BsTwitterX />
                </Link>
                <Link
                  className="social__wrapper"
                  to="https://www.awabio.com/"
                  target="_blank"
                >
                  <TfiWorld />
                </Link>
              </p>
              <p className="text-page-not-available text-start mt-3">
                <em>à bientôt....</em>
              </p>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="illustration-container na-image">
              <img src={naImage} alt="cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotAvailable;
